<div #element class="panel-actions-wrapper">
  <p-button
    icon="pi pi-bars"
    [text]="true"
    (mousedown)="toggleMenu()"
    [title]="'serviceDlp.headerActions.openMenu' | translate"
  ></p-button>
  <p-button
    icon="pi pi-refresh"
    [text]="true"
    (mousedown)="reloadPanel()"
    [title]="'serviceDlp.headerActions.reloadPanel' | translate"
  ></p-button>
  <p-button
    icon="pi pi-external-link"
    [text]="true"
    (mousedown)="popout()"
    [title]="'serviceDlp.headerActions.openInNewWindow' | translate"
  ></p-button>
  <p-button
    icon="pi pi-expand"
    [text]="true"
    (click)="toggleFullscreen()"
    [title]="'serviceDlp.headerActions.maximizeView' | translate"
  ></p-button>
</div>
