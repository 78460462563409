import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IGroupHeaderProps, IHeaderActionsRenderer } from 'dockview-core';
import { ButtonModule } from '@mship/design-ng/base/button';
import { TooltipModule } from '@mship/design-ng/base/tooltip';
import { WindowManagerService } from '../../shared/window-manager/window-manager.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dlp-right-header-action',
  standalone: true,
  imports: [CommonModule, ButtonModule, TooltipModule, TooltipModule, TranslateModule],
  templateUrl: './right-header-action.component.html',
  styleUrl: './right-header-action.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightHeaderActionComponent implements IHeaderActionsRenderer {
  @ViewChild('element') elementRef!: ElementRef;

  params!: IGroupHeaderProps;

  get element() {
    this.changeDetectorRef.detectChanges();
    return this.elementRef.nativeElement;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private windowManagerService: WindowManagerService,
  ) {}

  init(params: IGroupHeaderProps): void {
    this.params = params;
  }

  dispose(): void {
    console.log('dispose');
  }

  toggleMenu() {
    const frame = this.params.group.activePanel?.params?.['frame'] as ElementRef;
    frame.nativeElement.contentWindow.postMessage({ id: 'launchMenu', message: 'show' }, frame.nativeElement.src);
  }

  reloadPanel() {
    const frame = this.params.group.activePanel?.params?.['frame'] as ElementRef;
    frame.nativeElement.contentWindow.location.reload();
  }

  toggleFullscreen() {
    if (this.params.containerApi.hasMaximizedGroup()) {
      this.params.containerApi.exitMaximizedGroup();
    } else {
      this.params.api.maximize();
    }
  }

  popout() {
    this.windowManagerService.openDragAndDrop(this.params);
  }
}
